<template>
  <div class="career-fair-table-wrapper">
    <div class="card-header">
      <span class="title">{{ title }}</span>
      <a target="_blank" v-if="link" class="more" :href="link" type="text">{{linkText||'查看更多 >>'}}</a>
    </div>
    <el-table :data="tableData" @row-click="rowClick" height="100%" row-class-name="table-class" cell-class-name="ellipsis" size="mini" highlight-current-row>
      <el-table-column 
        label="活动名称" 
        width="410"
      >
        <template slot-scope="scope">
          <span class="tab-first-link" >{{scope.row.career_fair_name}}</span>
        </template>
      </el-table-column>
      <el-table-column 
        v-for="item in column" 
        :key="item.key"  
        :label="item.label" 
        :width="item.width||''"
        :prop="item.key"
        :formatter="item.formatter"
      >
      </el-table-column>
    </el-table>
  </div>
  
</template>
<script>
import {toActivityDetail} from "constants/routerLink"
export default {
  name:"CareerFairTableWrapper",
  props:["title","linkText","link",'limit'],
  data:function(){
    return {
      column:[],
      tableData:[],
    }
  },
  created(){
    this.getData()
  },
  mounted(){
    this.column = [
    {
      label:"主办单位",
      key:"valley_name",
      width:100
    },
    {
      label:"活动地点",
      key:"address",
    },
    {
      label:"活动开始时间",
      key:"start_time",
      width:100,
      formatter:(row)=>{
        return this.$dayjs(row.start_time).format("YYYY/MM/DD")
      }
    }]
  },
  methods:{
    rowClick(row){
      this.$router.push(`${toActivityDetail}${row.career_fair_id}`)
    },
    getData() {
      let params = {
          limit: this.limit||5,
          offset:0,
          valley_id:294
        }
      this.$axiosRequest({
        name: "getActivity",
        params: params
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data.results;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed:{
  },
  components:{
  },
  watch:{},
}
</script>
<style lang="scss">
  .career-fair-table-wrapper{
    padding: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .title {
      font-size: var(--large,16px);
      color: var(--titleColor);
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }
    .more {
      color: var(--moreColor,#666);
      &:hover {
        text-decoration: underline;
        
      }
    }
    .tab-first-link{
      color: var(--moreColor,#666);
    }
    .table-class{
      cursor: pointer;
    }
  }
  
</style>