<template>
  <div class="long-advertising">
    <a target="_blank" :href="link" v-if="link">
      <el-image :src="url" style="border-radius: 4px;"/>
    </a>
    <el-image v-else :src="url" style="border-radius: 4px;"/>
  </div>
</template>
<script>
export default {
  name: "longAdvertising",
  data: () => ({}),
  props:["url","link"],
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.long-advertising {
  background-color: $defaultBg;

}
</style>