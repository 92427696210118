<template>
  <div class="home page-container" style="margin-top:12px">
    <div>
      <HeaderContent  />
    </div>

    <div style="margin:18px 0 36px">
      <LongAdvertising v-for="o,i in longAdvertising" :key="i" :url="o.img_url" :link="o.link_src" style="margin: 5px 0"/>
    </div>

    <div>
      <TextHeader title="最新职位" :link="toJobs" />
      <div>
        <JobListWrapper cardVersion="new" :nums="18"/>
      </div>
    </div>

    <div>
      <TextHeader title="热招企业" :link="toCompanys" />
      <div>
        <CompanyListWrapper :nums="18"/>
      </div>
    </div>
    
    <div >
      <TextHeader title="热门活动" :link="toActivity" />
      <div>
        <ActivityListWrapper/>
      </div>
    </div>

    
    <div style="">
      <FixedBox />
    </div>
  </div>
  
</template>
<script>
// import Nums from "components/home/nums";
// import LoginBtns from "components/home/loginBtns";
// import NewsCard from "components/newsCard";
import TextHeader from "components/textHeader";
// import LongNews from "components/home/longNews";
// import SquareSwiper from "components/squareSwiperBanner";
import CompanyListWrapper from "components/company/companyListWrapper"
import HeaderContent from "components/home/headerContent"

// import BannerSwiper from "components/bannerSwiper";
import LongAdvertising from "components/longAdvertising";
import ActivityListWrapper from "components/activity/activityListWrapper";
import JobListWrapper from "components/jobs/jobListWrapper";
import FixedBox from "components/fixedBox";

import {keyEnterprises } from "constants/publicData";
import {
  toJobs,
  toCompanys,
  toActivity,
  toInformation,
} from "constants/routerLink";

import "../../constants/monitoringScript/buryingPoint.js"
import "../../constants/monitoringScript/bp_cfg.js";
import "../../constants/monitoringScript/md5.js";

export default {
  name: "Home",
  data: () => ({
    longAdvertising:null,
  }),
  computed: {
    toJobs: () => toJobs,
    toCompanys: () => toCompanys,
    toActivity: () => toActivity,
    toInformation: () => toInformation,
    keyEnterprises:()=>keyEnterprises,
  },
  created() {
    // this.getValleyMessage(1), this.getValleyMessage(2),
    this.getAdvertiseData()
  },
  methods: {
  
    getAdvertiseData(){
      this.$axiosRequest({
        name: "getAdvertise",
        params: {advertise_type:1}
      })
        .then((res) => {
          if (res.status === 200) {
              this.longAdvertising = res.data.advertise;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  components: {
    HeaderContent,
    // Nums,
    // LoginBtns,
    // NewsCard,
    TextHeader,
    // LongNews,
    // SquareSwiper,
    // BannerSwiper,
    LongAdvertising,
    ActivityListWrapper,
    JobListWrapper,
    FixedBox,
    CompanyListWrapper,

  },
};
</script>

<style lang="scss">
@import 'constants/style/public';
.home-nums {
  padding: 46px 0 26px;
}
.news-content{
  width: 100%;
}
.key-enterprises{
  margin-bottom: 50px;
  .list-box{
    display: flex;
    flex-wrap: wrap;
    .item-img{
      background-color: $defaultBg;
      box-shadow: 0 0 18px $shadowColor;
      flex:0 0 auto;
      border-radius: $borderRadius;
      margin-right:22px;
      margin-bottom: 22px;
      &:nth-of-type(10n){
        margin-right: 0;
        
      }
    }
  }
  
}
</style>