<template>
  <div>
    <div v-if="searchType===null">
      <el-input v-bind="$attrs" v-on="$listeners" v-model="searchVal" />
    </div>
    <div class="compound-wrapper" v-if="searchType==='compound'">
      <el-input class="compound-input" v-bind="$attrs" v-on="$listeners" :value="searchVal" v-model="searchVal">
        <el-select slot="prepend" class="compound-select"  v-bind="$attrs" v-on="$listeners" v-model="selectType"  placeholder="请选择">
          <el-option v-for="item in sel" :key="item.name" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-button @click="searched" slot="append" class="search-button">
          <i class="el-icon-search el-icon"></i>
          <span>{{searchText}}</span>
        </el-button>
      </el-input>
    </div>
  </div>
</template>
<script>
export default {
  name:"ComponentSearchInput",
  props:{
    options:{
      type:[Array,null,undefined],
      default:()=>[]
    },
    searchType:{
      type:[String,null,undefined],
      default:null
    },
    searchText:{
      type:[String],
      default:'搜 索'
    }
  },
  data:()=>({
    searchVal:"",
    selectType:null
  }),
  mounted(){
    if(this.sel[0]) this.selectType = this.sel[0].value
  },
  methods:{
    searched(){
      this.$emit('searched',this.searchVal,this.selectType)
    },
    onChange(){
      this.$emit('onChange',this.searchVal)
    },
    onSelect(){
      this.$emit('onSelect',this.selectType)
    }
  },
  computed:{
    sel(){
      let options = this.options
      if(Array.isArray(options)&&options.length>0){
        if(options.every(o=> o instanceof Object && o.name!==undefined && o.value!==undefined)){
          return options
        }else{
          return options.map(o=>({value:o,name:o}))
        }
      }else{
        console.error("options的应该是一个数组");
        return []
      }
    }
  },
  components:{},
  watch:{},

}
</script>
<style lang="scss">
  @import "constants/style/public";
  .compound-wrapper{
    width: 100%;
    border: 1px solid var(--borderLineColor,#F4F4F4);
    overflow: hidden;
    .compound-input,.el-input__inner{
      height: 54px;
      &:focus{
        border-color: $moreColor;
      }
    }
    .compound-select{
      min-width: 180px;
      border-right: 1px solid var(--borderLineColor,#F4F4F4);
    }
    .el-input-group__append,.el-input-group__prepend,.el-input__inner{
      border: 0;
    }
    .el-input-group__prepend{
      background-color: #fff;
    }
    .el-input-group__append {
      .el-button{
        padding: 0;
        color:#fff;
        font-size: 16px;
        &:active{
          filter: grayscale(0.2);
        }
      }
      .search-button{
        width: 160px;
        height: 100%;
        background-color: var(--moreColor,#666);
      }
    }
    
    
  }
</style>