<template>
  <div class="home-header-content">
    <div style="margin-bottom:12px;width:1200px" >
      <SearchInput searchType="compound" :options="selectOptions" @searched="onSearched"/>
    </div>
    <div class="home-header-top">
      <div class="aside-content">
        <AsideBox :options="category" @onClick="asideClick"/>
      </div>
      <div class="banner-content">
        <header>
          <BannerSwiper :swiperList="bannerList" />
        </header>
        <footer class="home-activity-card">
          <CareerFairTable title="活动预告" :link="toActivity"/>
        </footer>
      </div>
    </div>
    <div class="home-header-bottom">
      <div class="home-information-card">
        <NewsCard
          title="公共服务"
          :link="toInformation+'?id=0'"
          :data="public_data"
        />
      </div>
      <div class="home-information-card" style="margin-left:12px">
        <NewsCard
          title="国企招聘"
          :link="toInformation+'?id=1'"
          :data="talent_data"
        />
      </div>
      <div class="contact-card">
        <Suggestion />
      </div>
    </div>
     
  </div>
</template>
<script>
import NewsCard from "components/newsCard";
import AsideBox from "components/home/headerContent/aside"
import BannerSwiper from "components/bannerSwiper"
import SearchInput from "components/functionalComponents/searchInput"

// import LoginBtns from "components/home/headerContent/loginBtns"
import CareerFairTable from "components/home/headerContent/careerFairTable"
import Suggestion from "components/suggestion"

import {category} from "constants/publicData"
// import {keyEnterprises } from "constants/publicData";
import {
  // toJobs,
  // toCompanys,
  toActivity,
  toInformation,
} from "constants/routerLink";

export default {
  name:"homeHeadWrapper",
  data:()=>({
    talent_data:null,
    public_data:null,
    bannerList:null
  }),
  created(){
    this.getValleyMessage(1), this.getValleyMessage(2),
    this.getAdvertiseData()
  },
  methods:{
    onSearched(val,type){
      this.$router.push({name:type,params:{search_content:val}})
    },
    asideClick(val){
      // console.log(val);
      this.$router.push({name:'jobs',params:{industry:val}})
    },
    getValleyMessage(message_type) {
      // 1公共 2人才
      this.$axiosRequest({
        name: "getValleyMessage",
        params: Object.assign({
          limit: 6,
          message_type: message_type,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data.results);
            if (message_type === 2) {
              this.talent_data = res.data.results;
            } else {
              this.public_data = res.data.results;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAdvertiseData(){
      this.$axiosRequest({
        name: "getAdvertise",
        params: {advertise_type:2}
      })
        .then((res) => {
          if (res.status === 200) {
              this.bannerList = res.data.advertise;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed:{
    toActivity:()=>toActivity,
    toInformation:()=>toInformation,
    category:()=>category,
    selectOptions:()=>[{name:'找工作',value:'jobs'},{name:'找企业',value:'company'},{name:'看资讯',value:'information'},{name:'看活动',value:'activity'}],
  },
  components:{
    AsideBox,
    BannerSwiper,
    NewsCard,
    SearchInput,

    CareerFairTable,
    Suggestion

  },
  watch:{},

}
</script>
<style lang="scss">
// @import "constants/style/public";
.home-header-content{

  .aside-content,
  .banner-content header,
  .banner-content header,
  .home-information-card,
  .login-box-card,
  .home-activity-card,
  .contact-card{
    border: 1px solid var(--borderLineColor,#F4F4F4);
    // box-shadow: 0px 10px 18px var(--shadowColor);
    // border-radius: var(--borderRadius);
    overflow:hidden;
    background-color: #fff;
  }

  .aside-content{
    overflow: visible;
    height: 530px;
  }
  .home-information-card{
    width: 438px;
    height: 222px;
  }
  
  .home-header-top,footer,.home-header-bottom{
    display: flex;
  }
  .home-header-top{
    margin-bottom: 12px;
  }
  .aside-content{
    width: 260px;
    height: 530px;
    flex: none;
  }
  .banner-content{
    margin-left: 12px;
    width: 100%;
    header{
      width:928px;
      margin-bottom: 12px;
      height: 320px;
    }
  }
  .login-box-card{
    width:260px;
    height: 195px;
    margin-left: 12px;
  }
  .home-activity-card{
    width:928px;
    height: 198px;
  }
  .contact-card{
    width: 298px;
    height: 222px;
    margin-left: 12px;
  }
  
}
</style>