<template>
  <div class="fixed-box" :class="customClass" v-if="!(isMoble)" :style="customStyle">
    <div class="fixed-cont">
        <div class="title"></div>
        <div class="footer-qr">
          <div>扫码在线咨询</div>
          <img style="width:100%;hight:100%" src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGI8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAybTJUdUlKMk9lY0QxMDAwMGcwN0IAAgQpiARjAwQAAAAA" alt="">
        </div>
        <div><a target="_blank" :href="links.userPDF"> 下载求职者操作手册</a></div>
        <div><a target="_blank" :href="links.companyPDF"> 下载会员单位操作手册</a></div>
    </div>
  </div>
</template>
<script>
import {links} from 'constants/routerLink';
export default {
  name:"",
  props:["customClass","customStyle"],
  data:()=>({
    
  }),
  created(){
    // console.log(navigator.userAgent);

  },
  methods:{
    
  },
  computed:{
    links:()=>links,
    isMoble(){
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    }
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.fixed-box{
  position: fixed;
  z-index: 9;
  top: 45%;
  background-color: #fff;
  left: calc((100vw - 1200px) / 2 + 1200px);
  font-size: 14px;
  }

  .fixed-cont{
    padding: 24px 15px;
    width: 175px;
    // height: 135px;
    border: 1px solid var(--borderLineColor,#F4F4F4);
    // box-shadow: 0px 10px 18px rgba(105, 105, 105, 0.16);
    // border-radius: 6px;
    font-weight: 400;
    color: $cardsubTextColor;
    .title{
      color: $moreColor;
      font-size: $large;
      font-weight: 500;
      margin-bottom: 12px;
    }
    >div{
      margin-bottom: 12px;
    }
    .footer-qr{
        width:100%;
        text-align:center;
        transform:translate3d(0 -50% 0)
      }
  }
  
  

</style>