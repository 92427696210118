<template>
  <aside class="home-aside" @mouseenter="childIsShow" @mouseleave="childIsShowHidden">
    <ul class="list-content">
      <li class="list-item" v-for="(item,index) in options" :key="item.label" @mouseenter="showList(index,$event)" > <span>{{item.label}}</span> <i class="el-icon-arrow-right el-icon" /> </li>
    </ul>
    <div class="aside-child" v-if="isShow">
      <div v-for="(item) in childList" :key="item.label" class="aside-child-list" style="display:flex">
        <span class="aside-child-title">{{item.label}}</span>
         <div v-for="child in item.children" :key="child.label">
           <el-tag class="aside-tag" size="small" @click="()=>{$emit('onClick',`${childLabel}-${item.label}-${child.label}`)}" >{{child.label}}</el-tag>
         </div>
      </div>
     
    </div>
  </aside>
  
</template>
<script>
export default {
  name:"homeHeadAside",
  // model:{
  //   props:"value",//props的默认值
  //   event:"change"//触发的事件
  // },
  props:["options"],
  data:()=>({
    isShow:false,
    childList:[],
    childLabel:""
  }),
  mounted(){
    this.childList = this?.options[0]?.children
    this.childLabel = this?.options[0]?.label
  },
  methods:{
    showList(index){
     this.childList = this.options[index].children
     this.childLabel = this?.options[index]?.label
    },
    childIsShowHidden(){
      this.isShow=false
    },
    childIsShow(){
      this.isShow=true
    }
  },
  computed:{
  },
  components:{
  },
  watch:{},
}
</script>
<style lang="scss">
@import "constants/style/public";
ul{
  padding: 0;
  margin: 0;
  
}
li{
  text-decoration: none;
  list-style: none;
  text-align: left;
  font-size: 13px;
}
.home-aside{
  z-index: 9;
  padding: 14px 12px;
  position: relative;
  // border: 1px solid var(--borderLineColor,#F4F4F4);
  // box-shadow: 0px 10px 18px var(--shadowColor);
  // border-radius: var(--borderRadius);
  width: 260px;
  height: 530px;
  .el-icon{
    display: flex;
    align-items: center;
  }
  .list-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .list-item{
    padding: 3px 12px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover{
      background-color: var(--moreColor,#666);
      color: #fff;
    }
  }
  .aside-child{
    background-color: #fff;
    // border-radius: var(--borderRadius);
    border: 1px solid var(--borderLineColor,#F4F4F4);
    position: absolute;
    top: -1px;
    left: 260px;
    width: 939px;
    height: 528px;
    text-align: left;
    overflow-y: auto;
    padding: 12px 12px 0 24px;
    .aside-child-list{
      font-size: 14px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .aside-child-title{
        margin-right: 20px;
        margin-bottom: 10px;
      }
      .aside-tag{
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        color: $primaryColor;
        border: 1px solid $primaryColor;
        background-color: rgba($color: $primaryColor, $alpha: 0.05);
        transition: all 0.3s;
        &:hover{
          background-color: $primaryColor;
          color: #fff;
        }
      }
    }
  }
}





</style>