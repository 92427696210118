<template>
  <div class="news-box-card">
      <div class="card-header">
        <span class="title">{{ title }}</span>
        <a target="_blank" v-if="link" class="more" :href="link" type="text">{{text}}</a>
      </div>

      <div v-if="data" class="card-list">
        <div v-for="o in data" :key="o.valley_message_id" class="card-item">
          <a target="_blank" :href=" o.outside_link||toInformationDetail+o.valley_message_id" class="left-text toLink ">
            <div v-if='data.hot || isNew' class="hot-and-new">
              <span class="txt" v-if='o.hot'>
                <el-image
                  class="hot"
                  :src="hotImg"
                  fit="cover"></el-image>
              </span>
              <span class="txt" v-else>
                <el-image
                  class="new"
                  :src="newImg"
                  fit="cover"></el-image>
              </span>
            </div> 
            <div style="position:relative;" class="text ellipsis">
              {{o.message_title||'文章标题'}}&nbsp;&nbsp;
            </div>
          </a>
          <div class="right-time">
            {{updateTime(o.published_at)}}
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import {toInformationDetail} from "constants/routerLink"
export default {
  name: "NewsCard",
  props: {
    width: {
      type: [String, Number],
      default: "100%",
    },
    height: {
      type: [String, Number],
      default: "100%",
    },
    link: [String],
    title: [String],
    text: {
      type: [String],
      default: "查看更多>>",
    },
    data: {
      type:[Array],
      default: null
    },
  },
  data: () => ({}),
  methods: {
    updateTime(time){
      return this.$dayjs(time).format('YYYY/MM/DD')
    },
    isNew(hot,updated_at){
      return !!(!hot &&( this.$dayjs().diff(this.$dayjs(updated_at),'day') <=7 ))
    }  
  },
  computed: {
    hotImg:()=>require("assets/information_hot.png"),
    newImg:()=>require("assets/information_new.png"),
    toInformationDetail:()=>toInformationDetail,
    reWidth() {
      return parseInt(this.width);
    },
    reHeight() {
      if (this.height !== "auto") {
        return parseInt(this.height);
      } else {
        return "auto";
      }
    },
    
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.news-box-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  .left-text {
      display: flex;
      align-items: center;
      text-align: left;
      max-width: 70%;
      &.toLink{
        color: #333;
        cursor: pointer;
        &:hover{
          color:var(--moreColor,#666)
        }
      }
      .text{
        
      }
    }
  .title {
    display: flex;
    max-width: 620px;
    margin-right: 120px;
    color: $titleColor;
    align-items: center;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .new-style{
    position:absolute;
    top: 0px;
    right: 0px;
    width: 8px;
    height: 8px;
    background: radial-gradient(circle,red 35%,transparent);
    border-radius: 50%;
  }
  .hot-and-new{
    width: 14px;
    height: 14px;
    flex: none;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    .hot,.new{
      width: 100%; 
      height: 100%;
    }
  }
  .more {
    color: $moreColor;
    &:hover {
      text-decoration: underline;
    }
  }
  .el-card__body{
    display: flex;
    flex-direction: column;
    height: 100%
  }
  .card-list{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-item {
    background-color: $defaultBg;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: $little;
    &:last-child {
      margin-bottom: 0;
    }
    
  }
}
</style>

